h1.app-title {
  font-size: inherit;
  font-family: 'Baloo Bhaina 2', 'cursive';
}

@media (max-width: 350px) {
  h1.app-title {
    font-size: 18px;
  }
}
