.Padding-box {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 88px;
}

.Groceries-container {
  margin-left: 16px;
  padding-top: 12px;
  margin-right: 16px;
}

.Completed-container {
  margin: 0 16px 16px 16px;
}

@media only screen and (max-width: 600px) {
  .Padding-box {
    padding-top: 64px;
  }
  .Groceries-container {
    margin-left: 4px;
    margin-right: 4px;
    padding-top: 24px;
  }
  .Completed-container {
    margin-left: 4px;
    margin-right: 4px;
  }
}