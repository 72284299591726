.container {
  position: relative;
  margin: auto;
  display: block;
  margin-top: 5%;
  width: 600px;
  height: 420px;
  animation-name: load-screen;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  /* Optional styles to see container */
  /* background: none; */
  /* border: solid 4px red; */
}

.bear {
  z-index: 2;
}

.head {
  background: #fff;
  position: absolute;
  border-radius: 50%;
  width: 20%;
  height: 21%;
  top: 3%;
  left: 25%;
  border-bottom-left-radius: 50% 90%;
  border-bottom-right-radius: 50% 90%;
  border-top-left-radius: 125% 150%;
  border-top-right-radius: 125% 150%;
  z-index: 2;
  animation-name: head-bobbing;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.head-copy {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  border-bottom-left-radius: 50% 90%;
  border-bottom-right-radius: 50% 90%;
  border-top-left-radius: 125% 150%;
  border-top-right-radius: 125% 150%;
  z-index: 2;
}

.left-eye {
  position: absolute;
  width: 13%;
  height: 15%;
  border-radius: 50%;
  top: 30%;
  left: 23%;
  background: #111;
  z-index: 2;
}

.left-pupil {
  position: absolute;
  background: white;
  height: 22%;
  width: 22%;
  border-radius: 50%;
  top: 22%;
  right: 32%;
}

.right-eye {
  position: absolute;
  width: 13%;
  height: 15%;
  border-radius: 50%;
  top: 30%;
  right: 23%;
  background: #111;
  z-index: 2
}

.right-pupil {
  position: absolute;
  background: white;
  height: 22%;
  width: 22%;
  border-radius: 50%;
  top: 22%;
  left: 32%;
}

.left-ear {
  position: absolute;
  background: #fff;
  left: -6%;
  border-radius: 50%;
  height: 35%;
  width: 30%;
  z-index: 1;
}

.right-ear {
  position: absolute;
  background: #fff;
  right: -6%;
  border-radius: 50%;
  height: 35%;
  width: 30%;
  z-index: 1;
}

.inner-ear {
  position: absolute;
  background: pink;
  border-radius: 50%;
  height: 70%;
  width: 70%;
  top: 15%;
  left: 15%;
}

.snout {
  position: absolute;
  background: #eeeeee;
  height: 47%;
  width: 35%;
  border-bottom-left-radius: 85% 65%;
  border-bottom-right-radius: 85% 65%;
  border-top-left-radius: 90% 130%;
  border-top-right-radius: 90% 130%;
  top: 46%;
  left: 32.5%;
  z-index: 2;
}

.nose {
  background: black;
  position: absolute;
  height: 32%;
  width: 50%;
  top: 23%;
  left: 25%;
  border-bottom-left-radius: 350px 300px;
  border-bottom-right-radius: 350px 300px;
  border-top-left-radius: 350px 150px;
  border-top-right-radius: 350px 150px;
}

.upper-lip {
  background: black;
  position: absolute;
  height: 23%;
  width: 9%;
  top: 53%;
  left: 46.25%;
}

.lip-left {
  background-image: radial-gradient(circle at top center, rgba(255, 255, 255, 0) 40%, black 41%, black 60%, rgba(255, 255, 255, 0) 61%);
  background-size: 60%;
  background-position: bottom;
  background-repeat: no-repeat;
  position: absolute;
  top: 67%;
  left: 5%;
  width: 60%;
  height: 22%;
}

.lip-right {
  background-image: radial-gradient(circle at top center, rgba(255, 255, 255, 0) 40%, black 41%, black 60%, rgba(255, 255, 255, 0) 61%);
  background-size: 60%;
  background-position: bottom;
  background-repeat: no-repeat;
  position: absolute;
  top: 67%;
  right: 4%;
  width: 60%;
  height: 22%;
}

.torso {
  position: absolute;
  height: 60%;
  width: 25%;
  background: white;
  top: 16.5%;
  left: 24.5%;
  border-top-right-radius: 110px 200px;
  border-top-left-radius: 55px 200px;
  border-bottom-right-radius: 60px 55px;
  border-bottom-left-radius: 60px 55px;
  z-index: -1;
  box-shadow: -4px 10px 14px -5px rgba(0, 0, 0, 0.15);
}

.right-arm {
  position: absolute;
  width: 70%;
  height: 13%;
  background: white;
  top: 31%;
  left: 45%;
  border-left: 1px solid rgba(0,0,0,0.075);
  border-top: 1px solid rgba(0,0,0,0.075);
  border-radius: 30px 80% 80% 30px;
  transform: rotate(8deg);
  box-shadow: 5px 10px 20px -10px rgba(23, 36, 44, 0.375);
  z-index: 1;
}

.left-arm {
  position: absolute;
  height: 10%;
  width: 40%;
  background: white;
  transform: rotate(25deg);
  top: 25%;
  left: 70%;
  border-radius: 30px 80% 80% 30px;
  border-right: 1px solid rgba(0,0,0,0.075);
  border-top: 1px solid rgba(0,0,0,0.075);
    box-shadow: 13px 10px 10px -10px rgba(250, 250, 250, 0.7);
}

.claws {
  position: absolute;
  height: 75%;
  width: 9%;
  background: repeating-linear-gradient(rgba(255, 255, 255, 0) 0.01% 15%, black 10% 30%);
  background-repeat: no-repeat;
  right: -2%;
  top: 10.5%;
}

.legs > div {
  animation-name: walk;
  animation-duration: 0.4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  bottom: 16.5%;
}

.right-leg {
  position: absolute;
  height: 10%;
  width: 6.25%;
  background: white;
  left: 38%;
  z-index: -2;
  border-top-left-radius: 25px 50px;
  border-top-right-radius: 25px 50px;
  border-bottom-left-radius: 50% 70%;
  border-bottom-right-radius: 10px 55px;
  box-shadow: -4px 7px 14px -10px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0,0,0,0.05);
  border-bottom: none;
}

.left-leg {
  position: absolute;
  height: 10%;
  width: 6.25%;
  background: white;
  left: 29%;
  border-top-right-radius: 25px 50px;
  border-top-left-radius: 25px 50px;
  border-bottom-left-radius: 50% 70%;
  border-bottom-right-radius: 10px 55px;
  box-shadow: -4px -3px 7.5px -4px rgba(0,0,0,0.15);
  border: 1px solid rgba(0,0,0,0.025);
  border-bottom: none;
  animation-delay: 0.2s;
}

.toes {
  position: absolute;
  height: 30%;
  width: 20%;
  transform: rotate(86deg);
  background: repeating-linear-gradient(rgba(255, 255, 255, 0) 0.01% 31%, black 30% 45%);
  background-repeat: no-repeat;
  background-position: 0 -2px;
  right: 30%;
  top: 76%;
}

.shopping-cart {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  top: 0;
}

.handle {
  position: absolute;
  background: #BE202F;
  height: 13%;
  border-bottom-left-radius: 150% 20%;
  border-bottom-right-radius: 150% 20%;
  width: 1.75%;
  transform: rotate(105deg);
  left: 56%;
  top: 34.5%;
}

.back {
  position: absolute;
  background: #e6e6e6;
  height: 28%;
  border-bottom-left-radius: 150% 20%;
  border-bottom-right-radius: 170% 20%;
  width: 1.75%;
  transform: rotate(160deg);
  left: 63.15%;
  top: 40.5%;
}

.body-top {
  position: absolute;
  background: #e6e6e6;
  height: 32%;
  border-top-left-radius: 150% 20%;
  border-top-right-radius: 170% 20%;
  width: 1%;
  transform: rotate(91deg);
  left: 74%;
  top: 34.5%;
}

.body-front {
  position: absolute;
  background: #e6e6e6;
  height: 16%;
  border-bottom-left-radius: 150% 20%;
  border-bottom-right-radius: 170% 20%;
  width: 1.25%;
  transform: rotate(10deg);
  left: 83.5%;
  top: 50.5%;
}

.body-bottom {
  position: absolute;
  background: #e6e6e6;
  height: 25%;
  border-top-left-radius: 150% 20%;
  border-top-right-radius: 170% 20%;
  width: 1.25%;
  transform: rotate(88deg);
  left: 74.45%;
  top: 53.75%;
}

.body-vertical-stripes {
  position: absolute;
  transform: rotate(90deg);
  height: 24.5%;
  width: 11%;
  background: repeating-linear-gradient(rgba(255, 255, 255, 0) 0.01% 18%, #e6e6e6 14% 20%);
  background-repeat: no-repeat;
  right: 19.25%;
  bottom: 29%;
}

.body-horizontal-stripes {
  position: absolute;
  height: 14%;
  width: 20%;
  background: repeating-linear-gradient(rgba(255, 255, 255, 0) 0.01% 37%, #e6e6e6 33% 41%);
  background-size: 95%;
  background-repeat: no-repeat;
  right: 15%;
  bottom: 35.5%;
}

.body-to-base {
  position: absolute;
  background: #e6e6e6;
  height: 8%;
  border-top-left-radius: 150% 20%;
  border-top-right-radius: 170% 20%;
  width: 1.25%;
  transform: rotate(39deg);
  left: 64.5%;
  top: 66%;
}

.base-curve {
  position: absolute;
  background: radial-gradient(circle at center right, rgba(255, 255, 255, 0) 45%, #e6e6e6 46%);
  height: 6%;
  width: 3.15%;
  transform: rotate(-20deg);
  border-radius: 50%;
  left: 62.5%;
  top: 71.95%;
}

.base-bottom {
  position: absolute;
  background: #e6e6e6;
  height: 1.85%;
  border-bottom-left-radius: 170% 30%;
  border-top-left-radius: 170% 20%;
  width: 21%;
  left: 64.25%;
  top: 76.25%;
}

.wheels > div {
  position: absolute;
  width: 4.235%;
  height: 6%;
  background: linear-gradient(#BE202F 0, #7d151f 100%);
  border: 0.5px solid black;
  padding: 1%;
  border-radius: 50%;
  top: 77%;
  animation-name: wheel-turn;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.back-wheel {
  left: 65%;
  animation-delay: 0.3s;
}

.front-wheel {
  left: 79%;
}

.wheel-inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;  
  background: radial-gradient(circle at center right, #cccccc 65%, #eaeaea 70%);
}

.plumes > div {
  animation-name: speed-plume;
  animation-direction: forwards;
  animation-iteration-count: infinite;
  position: absolute;
  background-image: linear-gradient(to right, rgba(0,0,0,0), #eeeeee 100%);
  border-radius: 30%;
  opacity: 0;
  height: 0.75%;
  z-index: -1;
}

.plume-1 {
  width: 5%;
  top: 80%;
  left: 20%;
  animation-duration: 0.75s;
}
.plume-2 {
  width: 10%;
  top: 76.5%;
  left: 20%;
  animation-duration: 1.5s;
  animation-delay: 0.75s;
}
.plume-3 {
  width: 8%;
  top: 82%;
  left: 22%;
  animation-duration: 1.25s;
  animation-delay: 0.5s;
}
.plume-4 {
  width: 12%;
  top: 78.5%;
  left: 26%;
  animation-duration: 1.75s;
}

.plume-5 {
  width: 12%;
  top: 80.5%;
  left: 26%;
  animation-duration: 6s;
  animation-delay: 0s;
}

.fumes > div {
  animation-name: fume-cloud;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  border-radius: 50%;
  background: lightgray;
  opacity: 0;
  z-index: -3;
}

.fume-1 {
  width: 1%;
  height: 1.5%;
  top: 79.5%;
  right: 35%;
  animation-duration: 1s;
}

.fume-2 {
  width: 1.5%;
  height: 2%;
  top: 79.5%;
  right: 36%;
  animation-duration: 1s;
  animation-delay: 0.5s;
}

.fume-3 {
  width: 1.5%;
  height: 2%;
  top: 80.5%;
  right: 37%;
  animation-duration: 2s;
}

.fume-4 {
  width: 1%;
  height: 1.5%;
  top: 80.5%;
  right: 39%;
  animation-duration: 2s; 
  animation-delay: 0.75s;
}

.fume-5 {
  width: 1.5%;
  height: 2%;
  top: 80.5%;
  right: 34%;
  animation-duration: 3s;
}

.fume-5 {
  width: 1%;
  height: 1.5%;
  top: 80.5%;
  right: 34.5%;
  animation-duration: 2s;
  animation-delay: 1s;
}

.text {
  position: absolute;
  bottom: 0;
  width: 75%;
  left: 12.5%;
  text-align: center;
  color: white;
  font-size: 1.15rem;
}


@keyframes walk {
  50% {
    transform: translatey(-10%);
  }
}

@keyframes head-bobbing {
  50% {
    transform: translatex(0.5%) 
  };
}

@keyframes wheel-turn {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load-screen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes speed-plume {
  0% {opacity: 0;}
  5% {opacity: 1;}
  30%, 100% {width: 6%; opacity: 0; transform: translatex(-50%); }
}

@keyframes fume-cloud {
  0% {opacity: 0;}
  30% {opacity: 1;  transform: translate(-250%, -100%);}
  60% {opacity: 0; transform: translate(-450%, 30%);}
  70%, 100% {opacity: 0;}
}

@media (max-width: 599px) {
  .container {
    width: 320px;
    height: 224px;
    margin-top: 45%;
  }
  .text {
    font-size: 0.9rem;
    bottom: -7.5%;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .container {
    width: 320px;
    height: 224px;
    margin-top: 5%;
  }
  .text {
    font-size: 0.9rem;
    bottom: -7.5%;
  }
}